import Pagination from "@mui/material/Pagination";
import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import VideoBox from "../../components/Video";
import {
  SituationUserDto,
  SituationUserDtoHandler,
} from "../../dtos/SituationUserDto";
import "./style.scss";
import api from "../../services";
import { Market } from "../../enums";
import Nav from "../../components/Nav";
import AccountButton from "../../components/AccountButton/AccountButton";
import scrollToTop from "../../utils/scrollToTop";
import VideosOfflineButton from "../../components/VideosOfflineButton/VideosOfflineButton";
import { Box } from "@mui/material";
import {
  loadFilterPlanUser,
  loadVideo,
  loadVideoFilter,
} from "../../utils/api";
import { getHeaders, loadAuth } from "../../utils/auth";

export default function Home() {
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const navigate = useNavigate();
  const [posts, setPosts] = useState([{}]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(8);
  const [situationUser, setSituationUser] = useState<SituationUserDto>(
    SituationUserDtoHandler.getDefault()
  );
  const [isMarketClient, setIsMarketClient] = useState(
    situationUser.marketplace
  );
  const isMarketClientRef = useRef(isMarketClient);
  const [market, setMarket] = useState<Market>(Market.all);
  const marketRef = useRef(market);
  const [marketUser, setMarketUser] = useState([{}]);

  /*/ Derruba login simutaneamente
   useEffect(() => {
    const interval = setInterval(() => {
      loadAuth(handleAuth);
    }, 10000);
    return () => clearInterval(interval);
  }, []);*/

  //pegando o carregamento de dados
  useEffect(() => {
    loadAuth(handleAuth);
    loadSitution();
    const fetchPosts = async () => {
      setLoading(true);
      await loadVideo(setPosts);
      await loadFilterPlanUser(setMarketUser, isMarketClientRef);
      setLoading(false);
    };
    fetchPosts();
    // Update network status
    const handleStatusChange = () => {
      setIsOnline(navigator.onLine);
      if (navigator.onLine) {
        navigate(`/home`);
        console.log("Status da internet: " + navigator.onLine);
      } else {
        navigate(`/offline`);
        console.log("Status da internet: " + navigator.onLine);
      }
    };
    // Listen status
    window.addEventListener("online", handleStatusChange);
    window.addEventListener("offline", handleStatusChange);
    return () => {
      window.removeEventListener("online", handleStatusChange);
      window.removeEventListener("offline", handleStatusChange);
    };
  }, []);

  useEffect(() => {
    scrollToTop();
  }, [currentPage, posts, market]);

  //buscando situação do user pelo token.
  async function loadSitution() {
    await api
      .get("/contratos/situacao/", getHeaders())
      .then((response) => {
        const marketClient = SituationUserDtoHandler.getByDatabase(
          response.data[0]
        );
        isMarketClientRef.current = marketClient.marketplace;
        setIsMarketClient(isMarketClientRef.current);
        handleApple(isMarketClientRef.current);
        handleSituation(
          SituationUserDtoHandler.getByDatabase(response.data[0])
        );
        setSituationUser(
          SituationUserDtoHandler.getByDatabase(response.data[0])
        );
      })
      .catch(() => console.log("Erro ao autenticar o usuário"));
  }

  //Obtendo os posts atuais por pages
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost);

  //mudando as pages
  function paginate(pageNumber: any): void {
    return setCurrentPage(pageNumber);
  }

  function handleSituation(data?: any) {
    setSituationUser(SituationUserDtoHandler.getByDatabase(data));
    if (data.situation !== "Pago") {
      navigate("/contract");
    }
  }

  //manipulando o resultado do get da Auth
  function handleAuth(auth: boolean) {
    if (!auth) return;

    localStorage.removeItem("userJWT");
    navigate("/login");
  }

  async function handleApple(market: any) {
    if (market === -1) {
      return await loadVideoFilter(Market.all);
    }
    return;
  }

  async function handleChangeMarket(market: Market) {
    marketRef.current = market;
    setMarket(market);
    await loadVideoFilter(market, setPosts);

    if (market !== Market.all) return await loadVideoFilter(market, setPosts);

    await loadVideo();
  }

  function AbreOfflineAndroid() {
    try {
      window.Android.showOffline();
    } catch (e) {
      console.log("error", e);
    }
  }
  function AbreOfflineIos() {
    try {
      window.webkit.messageHandlers.viewOffline.postMessage("Botão Offline");
    } catch (e) {
      console.log("error", e);
    }
  }
  function AbreOfflineHandler() {
    AbreOfflineAndroid();
    AbreOfflineIos();
  }

  return (
    <>
      <div className="container__home">
        <div className="content__home">
          <div className="home-wrapper">
            <div className="container_btn_period">
              <button
                className={
                  market === Market.all ? "btn_plan active-tabs" : "btn_plan"
                }
                onClick={() => handleChangeMarket(Market.all)}
              >
                Todos
              </button>
              {marketUser.map((marketPlan: any) => (
                <button
                  className={
                    market === marketPlan.id
                      ? "btn_plan active-tabs"
                      : "btn_plan"
                  }
                  onClick={() => handleChangeMarket(marketPlan.id)}
                >
                  {marketPlan.nome}
                </button>
              ))}
            </div>
            <Box
              display={"flex"}
              flexDirection={"column"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <AccountButton />
              <VideosOfflineButton AbreOfflineHandler={AbreOfflineHandler} />
            </Box>
          </div>
          <div className="container__videos">
            <VideoBox posts={currentPosts} loading={loading} />
          </div>
          <div className="container_navigation">
            <Pagination
              count={Math.ceil(posts.length / postsPerPage)}
              color="primary"
              shape="rounded"
              showFirstButton={true}
              showLastButton={true}
              hideNextButton={true}
              hidePrevButton={true}
              onChange={(e, value) => paginate(value)}
              className="pagination"
            />
          </div>
        </div>
      </div>
      <Nav />
    </>
  );
}
