import { ChevronRight, RefreshRounded } from "@mui/icons-material";
import "./App.css";
import Routes from "./routes";
import PullToRefresh from "react-simple-pull-to-refresh";
import { useEffect, useState } from "react";
import moment from "moment";
import ReactModal from "react-modal";
import { getUserToken, loadAuth } from "./utils/auth";
import { useAuth } from "./components/ProtectedRoute";

export default function App() {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [loginValidation, setLoginValidation] = useState<any>();
  const token = getUserToken();
  const auth = useAuth();

  async function loadValidation() {
    const info = await loadAuth();
    setLoginValidation(info);
  }

  const reloadPage = () => {
    return new Promise<void>((resolve) => {
      window.location.reload();
      resolve(); // Resolving the promise immediately after reload
    });
  };

  useEffect(() => {
    loadValidation();
  }, [auth]);

  // expiring subscription message
  useEffect(() => {
    const lsExpiringMessage = localStorage.getItem("expiring");
    const expiringDate = moment(loginValidation?.cont_data_vencimento).diff(
      moment(),
      "days"
    );
    const isPortalSubscription = Number(loginValidation?.plan_id) >= 33; // filtering per subscription

    if (expiringDate <= 10) {
      if (!lsExpiringMessage && isPortalSubscription && token) {
        console.log("show toast");
        setIsOpen(true);
      }
    } else {
      localStorage.removeItem("expiring");
    }
  }, [
    loginValidation?.cont_data_vencimento,
    loginValidation?.plan_id,
    token,
    auth,
  ]);

  return (
    <PullToRefresh
      resistance={2}
      onRefresh={() => reloadPage()}
      pullingContent={
        <div className="w-full h-full flex justify-center items-center text-white">
          <RefreshRounded fontSize="large" className="animate-spin" />
        </div>
      }
    >
      <>
        <Routes />
        <ReactModal
          isOpen={modalIsOpen}
          onRequestClose={() => {
            localStorage.setItem("expiring", String(true));
            setIsOpen(false);
          }}
          ariaHideApp={false}
          overlayClassName={"fixed inset-0 bg-black/50 flex items-center"}
          className="bg-white p-4 w-full"
        >
          <div className="text-zinc-900">
            <h3 className="text-base font-semibold">
              Sua assinatura está prestes a expirar!
            </h3>
            <h4>
              Em{" "}
              {moment(loginValidation?.cont_data_vencimento).diff(
                moment(),
                "days"
              )}{" "}
              dias o período da sua assinatura se encerrará.
            </h4>
            <h4>
              Acesse via navegador
              <span className="text-blue-600 ml-1">rbvideo.com.br</span>
            </h4>
            <div className="flex gap-2 mt-2">
              <button
                onClick={() => {
                  localStorage.setItem("expiring", String(true));
                  setIsOpen(false);
                }}
                className="border p-2 text-zinc-900 rounded-md mt-1"
              >
                Fechar
              </button>
            </div>
          </div>
        </ReactModal>
      </>
    </PullToRefresh>
  );
}
