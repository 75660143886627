import api from "../../services";
import getHeaders from "./getHeaders";

export default async function loadAuth(handleAuth) {
  const result = await api
    .get("/clientes/logado", getHeaders())
    .then((response) => {
      const { data } = response;

      if (data.error) {
        handleAuth?.(response.data.error);
      }
      return response.data;
    })
    .catch((e) => console.log("Erro ao autenticar o usuário:", e));
  return result;
}
