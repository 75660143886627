import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_RB,
});

// Request interceptor to attach token automatically
api.interceptors.request.use((config) => {
  const tokenUser = localStorage.getItem("userJWT");
  const tokenJWT = tokenUser?.substring(1, tokenUser?.length - 1);

  if (tokenJWT) {
    config.headers["x-access-token"] = tokenJWT;
  }
  return config;
});

export default api;
